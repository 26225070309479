import Vue from 'vue'
import Vuex from 'vuex'
import { axios, axiosEdoFuerza, axiosFormData } from '../plugins/axios'
import axiosIph from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: localStorage.getItem('token') || '',
        perfil: localStorage.getItem('perfil') || '',
        nombre: localStorage.getItem('nombre') || '',
        idPersonal: localStorage.getItem('idPersonal') || '',
        cuip: localStorage.getItem('cuip') || '',

        snackbar: {
            active: false,
            message: '',
        },

        hits: [],
        padron: [],
        personal: [],
        personalData: null,
        estatusPersonal: [],
        instancias: [],
        tipoLectura: [],
        solicitudes: [],
        respuesta: [],

        valorConsulta: null,
        consultaHit: [],
        numeroHits: 0,

        consultaVehiculo: null,
    },

    getters: {
        getSnackbar: state => {
            return state.snackbar
        },

        getToken: state => {
            return state.token
        },

        getPerfil: state => {
            return state.perfil
        },

        getNombre: state => {
            return state.nombre
        },

        getIdPersonal: state => {
            return state.idPersonal
        },

        getCuip: state => {
            return state.cuip
        },

        getHits(state) {
            return state.hits
        },

        getPadron(state) {
            return state.padron
        },

        getPersonal(state) {
            return state.personal
        },

        getPersonalData(state) {
            return state.personalData
        },

        getPersonalActivo(state) {
            if (state.personal.length > 0) {
                return state.personal.filter(personal => {
                    return personal.estatus == 1
                })
            } else return []
        },

        getEstatusPersonal(state) {
            return state.estatusPersonal
        },

        getInstancias(state) {
            return state.instancias
        },

        getTipoLectura(state) {
            return state.tipoLectura
        },

        getSolicitudes(state) {
            return state.solicitudes
        },

        getRespuesta(state) {
            return state.respuesta
        },

        getValorConsulta(state) {
            return state.valorConsulta
        },

        getConsultaHit(state) {
            return state.consultaHit
        },

        getNumeroHits(state) {
            return state.numeroHits
        },

        getConsultaVehiculo(state) {
            return state.consultaVehiculo
        },
    },

    mutations: {
        SET_TOKEN(state, token) {
            state.token = token
        },

        SET_PERFIL(state, perfil) {
            state.perfil = perfil
        },

        SET_NOMBRE(state, nombre) {
            state.nombre = nombre
        },

        SET_ID_PERSONAL(state, idPersonal) {
            state.idPersonal = idPersonal
        },

        SET_CUIP(state, cuip) {
            state.cuip = cuip
        },

        SET_SNACKBAR_MESSAGE(state, message) {
            state.snackbar = {
                active: true,
                message: message,
            }
        },

        SET_HITS(state, hits) {
            state.hits = hits
            if (state.numeroHits == 0) {
                console.log('Hits iniciales')
                state.numeroHits = hits.length
            } else {
                console.log('Nuevo numero de hits:' + hits.length)
                console.log('Numero hits anterior: ' + state.numeroHits)
                if (state.numeroHits < hits.length) {
                    console.log('Nuevo hit')
                } else {
                    console.log('No hay hits nuevos')
                }
                state.numeroHits = hits.length
            }
        },

        SET_PADRON(state, padron) {
            state.padron = padron
        },

        SET_PERSONAL(state, personal) {
            state.personal = personal
        },

        SET_PERSONAL_DATA(state, personalData) {
            state.personalData = personalData
        },

        SET_ESTATUS_PERSONAL(state, estatusPersonal) {
            state.estatusPersonal = estatusPersonal
        },

        SET_INSTANCIAS(state, instancias) {
            state.instancias = instancias
        },

        SET_TIPO_LECTURA(state, tipoLectura) {
            state.tipoLectura = tipoLectura
        },

        SET_SOLICITUDES(state, solicitudes) {
            state.solicitudes = solicitudes
        },

        SET_RESPUESTA(state, respuesta) {
            state.respuesta = respuesta
        },

        SET_VALOR_CONSULTA(state, valorConsulta) {
            state.valorConsulta = valorConsulta
        },

        SET_CONSULTA_HIT(state, consultaHit) {
            state.consultaHit = consultaHit
        },

        SET_CONSULTA_VEHICULO(state, consultaVehiculo) {
            state.consultaVehiculo = consultaVehiculo
        },
    },

    actions: {
        async fetchHits({ commit }) {
            await axios
                .get('hits.php')
                .then(response => {
                    commit('SET_HITS', response.data)
                })
                .catch(error => {
                    console.log(error)
                })
        },

        async fetchPadron({ commit }) {
            await axios
                .get('padron.php')
                .then(response => {
                    commit('SET_PADRON', response.data)
                })
                .catch(error => {
                    console.log(error)
                })
        },

        async fetchPersonal({ commit }) {
            await axios
                .get('personal.php')
                .then(response => {
                    commit('SET_PERSONAL', response.data)
                })
                .catch(error => {
                    console.log(error)
                })
        },

        async fetchPersonalByCuip({ commit }, cuip) {
            await axiosEdoFuerza
                .get('IPH/EstadoFuerza/personal/' + cuip)
                .then(response => {
                    commit('SET_PERSONAL_DATA', response.data.data[0])
                })
                .catch(error => console.log(error))
        },

        async fetchEstatusPersonal({ commit }) {
            await axios
                .post('catalogos.php', {
                    type: 'estatuspersonal',
                })
                .then(response => {
                    commit('SET_ESTATUS_PERSONAL', response.data)
                })
                .catch(error => {
                    console.log(error)
                })
        },

        async fetchInstancias({ commit }) {
            await axios
                .post('catalogos.php', {
                    type: 'instancias',
                })
                .then(response => {
                    commit('SET_INSTANCIAS', response.data)
                })
                .catch(error => {
                    console.log(error)
                })
        },

        async fetchTipoLectura({ commit }) {
            await axios
                .post('catalogos.php', {
                    type: 'tipoLectura',
                })
                .then(response => {
                    commit('SET_TIPO_LECTURA', response.data)
                })
                .catch(error => {
                    console.log(error)
                })
        },

        async fetchSolicitudes({ commit }) {
            await axios
                .get('solicitudes.php')
                .then(response => {
                    commit('SET_SOLICITUDES', response.data)
                })
                .catch(error => {
                    console.log(error)
                })
        },

        async fetchRespuestaBySolicitud({ commit }, idSolicitud) {
            var respuesta = new FormData()
            respuesta.append('idsolicitud', idSolicitud)
            respuesta.append('type', 'get')

            await axiosFormData
                .post('respuestas.php', respuesta)
                .then(response => {
                    commit('SET_RESPUESTA', response.data)
                })
                .catch(error => {
                    console.log(error)
                })
        },

        async fetchAdjuntoByName({ commit }, filename) {
            await axios
                .get('files/' + filename)
                .then(response => {})
                .catch(error => {
                    console.log(error)
                })
        },

        async login(context, user) {
            await axiosEdoFuerza
                .post('PoliciaCuadrantes/login', user)
                .then(res => {
                    if (res.data.data[0].token.length > 0) {
                        var token = res.data.data[0].token
                        var perfil = res.data.data[0].extraInfo.perfil
                        var nombre = res.data.data[0].fullName
                        var idPersonal = res.data.data[0].fk_idPersonal
                        var cuip = res.data.data[0].CUIP
                        localStorage.setItem('token', token)
                        localStorage.setItem('perfil', perfil)
                        localStorage.setItem('nombre', nombre)
                        localStorage.setItem('idPersonal', idPersonal)
                        localStorage.setItem('cuip', cuip)
                        context.commit('SET_TOKEN', token)
                        context.commit('SET_PERFIL', perfil)
                        context.commit('SET_NOMBRE', nombre)
                        context.commit('SET_ID_PERSONAL', idPersonal)
                        context.commit('SET_CUIP', cuip)
                        context.dispatch('savePersonal', { fullname: nombre, cuip: res.data.data[0].CUIP })
                    } else {
                        context.commit('SET_SNACKBAR_MESSAGE', 'No pudo realizarse el inicio de sesión. Por favor, verifique sus credenciales.')
                    }
                })
                .catch(error => context.commit('SET_SNACKBAR_MESSAGE', 'No pudo realizarse el inicio de sesión. Por favor, verifique sus credenciales.'))
        },

        async savePersonal({ commit }, personal) {
            await axios
                .post('personal.php', {
                    type: 'save',
                    nombre: personal.fullname,
                    lat: 19.12312313,
                    lng: -98.3423432,
                    estatus: 1,
                    cuip: personal.cuip,
                })
                .then(response => {
                    commit('SET_SNACKBAR_MESSAGE', 'Inicio de sesión realizado. Redirigiendo...')
                })
                .catch(error => {
                    commit('SET_SNACKBAR_MESSAGE', 'No pudo realizarse el inicio de sesión. Por favor, verifique sus credenciales.')
                })
        },

        async saveRespuesta({ commit }, respuesta) {
            await axiosFormData
                .post('respuestas.php', respuesta)
                .then(response => {
                    commit('SET_SNACKBAR_MESSAGE', 'Respuesta enviada.')
                })
                .catch(error => {
                    console.log(error)
                })
        },

        async deleteHit({ commit }, idHit) {
            await axios
                .post('hits.php', {
                    type: 'update',
                    id: idHit,
                })
                .then(response => {
                    commit('SET_SNACKBAR_MESSAGE', 'El hit ha sido archivado.')
                })
                .catch(error => {
                    console.log(error)
                })
        },

        async fetchConsultaHitByPlaca({ commit }, consulta) {
            commit('SET_VALOR_CONSULTA', consulta.valorConsulta)
            await axios
                .get('https://eonproduccion.net:44373/api/AnexoC/ConsultarVehiculo/' + consulta.idPersonal + '/' + consulta.valorConsulta)
                .then(response => {
                    commit('SET_CONSULTA_VEHICULO', response.data)
                    // console.log(response)
                })
                .catch(error => {
                    console.log(error)
                })
        },
    },
    modules: {},
})
