<template>
    <transition name="fade" mode="out-in">
        <router-view></router-view>
    </transition>
</template>
<script>
    import MapComponent from '../components/MapComponent.vue'

    export default {
        components: {
            MapComponent,
        },
    }
</script>
