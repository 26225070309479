import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import store from '../store/index.js'
import { publicPath } from '../../vue.config'

import Map from '../views/Map.vue'
import Tabletas from '../views/Tabletas.vue'
import RedVinculos from '../views/RedVinculos.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
    },
    {
        path: '/mapa',
        name: 'map',
        component: Map,
    },
    {
        path: '/tabletas',
        name: 'tabletas',
        component: Tabletas,
    },
    {
        path: '/red_vinculos/:placas',
        name: 'red_vinculos',
        component: RedVinculos,
        props: true,
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
]

const router = new VueRouter({
    mode: 'history',
    base: publicPath,
    routes,
})

router.beforeEach((to, from, next) => {
    if (to.name !== 'login' && store.state.token.length == 0) {
        next({ name: 'login' })
    } else {
        next()
    }
})

function castRouteParams(route) {
    return {
        id: Number(route.params.id),
    }
}

export default router
