<template>
    <div id="3d-graph"></div>
</template>
<style scoped>
    .node-label {
        font-size: 12px;
        padding: 1px 4px;
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        user-select: none;
    }
    .card {
        height: 48px !important;
        width: 64px !important;
        background-color: red !important;
    }
</style>
<script>
    import ForceGraph3D from '3d-force-graph'
    import { mapActions, mapGetters } from 'vuex'

    export default {
        props: {
            placas: {
                type: String,
            },
        },

        async mounted() {
            await this.fetchHits()

            await this.fetchConsultaHitByPlaca({
                idPersonal: 1,
                valorConsulta: this.placas,
            })

            this.redVinculos.nodes.push({
                id: this.redVinculos.nodes.length,
                name:
                    this.consultaVehiculo[0].vehiculo.marca +
                    ' ' +
                    this.consultaVehiculo[0].vehiculo.submarca +
                    ' ' +
                    this.consultaVehiculo[0].vehiculo.modelo +
                    ' ' +
                    this.consultaVehiculo[0].vehiculo.color,
                type: 'VEHICULO',
            })

            this.redVinculos.links.push({
                source: 0,
                target: 1,
            })

            this.redVinculos.nodes.push({
                id: this.redVinculos.nodes.length,
                name: this.consultaVehiculo[0].vehiculo.estatusRobo,
                type: 'ESTATUS',
            })

            this.redVinculos.links.push({
                source: 1,
                target: 2,
            })

            this.hits.forEach(hit => {
                if (hit.placas == this.placas) {
                    this.redVinculos.nodes.push({
                        id: this.redVinculos.nodes.length,
                        name: ' HIT ' + this.formatDate(hit.fechaHit.date) + ' A LAS ' + this.getHours(hit.fechaHit.date),
                    })
                    this.redVinculos.links.push({
                        source: 0,
                        target: this.redVinculos.nodes.length - 1,
                    })
                }
            })

            // this.redVinculos.nodes.push({
            //     id: this.redVinculos.nodes.length,
            //     name: 'ROBERTO GONZALEZ JIMÉNEZ',
            //     type: 'DUEÑO',
            // })

            // this.redVinculos.links.push({
            //     source: this.redVinculos.nodes.length,
            //     target: 2,
            // })

            const Graph = ForceGraph3D({
                extraRenderers: [new THREE.CSS2DRenderer()],
            })(document.getElementById('3d-graph'))
                .cooldownTicks(100)
                .graphData(this.redVinculos)
                .nodeAutoColorBy('type')
                .onNodeDragEnd(node => {
                    node.fx = node.x
                    node.fy = node.y
                    node.fz = node.z
                })
                .nodeThreeObject(node => {
                    const nodeEl = document.createElement('div')
                    nodeEl.textContent = node.name
                    nodeEl.style.color = '#FFFFFF'
                    nodeEl.className = 'card'
                    return new THREE.CSS2DObject(nodeEl)
                })
                .nodeThreeObjectExtend(true)

            Graph.onEngineStop(() => Graph.zoomToFit(50))
        },

        data() {
            return {
                redVinculos: {
                    nodes: [{ id: 0, name: this.placas, color: '#f68632' }],
                    links: [],
                },
            }
        },

        computed: {
            ...mapGetters({
                idPersonal: 'getIdPersonal',
                consultaVehiculo: 'getConsultaVehiculo',
                hits: 'getHits',
            }),
        },

        methods: {
            ...mapActions(['fetchConsultaHitByPlaca', 'fetchHits']),
        },
    }
</script>
