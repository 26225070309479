<template>
    <v-card class="card--list glass-menu-card" width="320">
        <v-card-title>
            <v-icon color="#FFC107" class="mr-2">mdi-map-marker</v-icon>
            <vue-typed-js :strings="['Hits reportados']" :loop="true" :smartBackspace="true" :backSpeed="30" :backDelay="3000">
                <h1 class="text-h6 white--text typing"></h1>
            </vue-typed-js>
        </v-card-title>
        <v-card-text>
            <v-virtual-scroll height="300" :items="filteredHits" item-height="280">
                <template v-slot:default="{ item }">
                    <v-card :key="item.idHit" color="accent" rounded>
                        <v-card-title class="d-block" align="center">
                            <div class="text-caption white--text">Reportado por</div>
                            <div class="text-subtitle-1 white--text mb-2">{{ item.personal }}</div>
                            <div class="text-caption white--text">Información el auto</div>
                            <div class="text-subtitle-1 white--text mb-2">{{ item.marca + ' ' + item.submarca + ' ' + item.modelo + ' ' + item.color }}</div>
                            <v-chip small>{{ item.tipoLectura }}</v-chip>
                        </v-card-title>
                        <v-card-actions class="justify-center">
                            <v-btn icon>
                                <v-icon color="white" v-on:click="triggerDeleteHit(item.idHit)">mdi-archive-arrow-down</v-icon>
                            </v-btn>
                            <v-btn icon>
                                <v-icon color="white" v-on:click="getUbicacionHit(item)">mdi-car-emergency</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </template>
            </v-virtual-scroll>
        </v-card-text>
    </v-card>
</template>
<style scoped>
    .card--list {
        position: fixed;
        bottom: 48px;
        left: 24px;
    }
    .glass-menu-card {
        background: rgba(7, 13, 45, 0.88) !important;
        box-shadow: 0 4px 24px 2px rgba(84, 110, 229, 0.2) !important;
        backdrop-filter: blur(2px) !important;
        -webkit-backdrop-filter: blur(2px) !important;
        border: 1px solid var(--v-secondary-base) !important;
    }

    .v-select__selection,
    .v-select__selection--comma,
    .v-select.v-text-field input {
        color: red !important;
    }
</style>
<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    export default {
        data() {
            return {
                modoElegido: 0,
            }
        },

        computed: {
            ...mapGetters({
                hits: 'getHits',
            }),

            filteredHits() {
                return this.hits.filter(hit => {
                    return hit.hitActivo == 1
                })
            },
        },

        methods: {
            ...mapActions(['deleteHit', 'fetchHits']),
            ...mapMutations(['SET_SNACKBAR_MESSAGE']),

            async triggerDeleteHit(id_hit) {
                await this.deleteHit(id_hit)
                await this.fetchHits()
            },

            getUbicacionHit(hit) {
                this.SET_SNACKBAR_MESSAGE('Obteniendo ubicación de hit.')
                this.$emit('fly', hit)
            },
        },
    }
</script>
