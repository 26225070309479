import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import es from 'vuetify/lib/locale/es'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#070D2D',
                secondary: '#546EE5',
                accent: '#FE5430',
                error: '#B00020',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                anchor: '#00003c',
                eon: '#f68632',
            },
            dark: {
                primary: '#070D2D',
                secondary: '#546EE5',
                accent: '#FE5430',
                error: '#B00020',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                eon: '#f68632',
            },
        },
        dark: true,
    },
    lang: {
        locales: { es },
    },
})
