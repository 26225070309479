import { render, staticRenderFns } from "./RedVinculos.vue?vue&type=template&id=0eda7b60&scoped=true&"
import script from "./RedVinculos.vue?vue&type=script&lang=js&"
export * from "./RedVinculos.vue?vue&type=script&lang=js&"
import style0 from "./RedVinculos.vue?vue&type=style&index=0&id=0eda7b60&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0eda7b60",
  null
  
)

export default component.exports