<template>
    <v-card class="card--list glass-menu-card" width="320">
        <v-card-title>
            <v-icon color="#3fb1ce" class="mr-2">mdi-map-marker</v-icon>
            <vue-typed-js :strings="['Tabletas activas: ' + personalActivo.length]" :loop="true" :smartBackspace="true" :backSpeed="30" :backDelay="3000">
                <h1 class="text-h6 white--text typing"></h1>
            </vue-typed-js>
        </v-card-title>
        <v-card-text>
            <v-text-field label="Buscar por CUIP" v-model="search" solo dense></v-text-field>
            <!-- <v-select
                v-model="tipoLecturaElegido"
                solo
                dense
                rounded
                background-color="secondary--button"
                label="Tipo de lectura"
                :items="selectTipoLectura"
                item-value="id"
                item-text="nombre"
            ></v-select> -->
            <v-virtual-scroll height="240" :items="filteredPersonalActivo" item-height="80">
                <template v-slot:default="{ item }">
                    <v-list-item :key="item.id" two-line dense>
                        <v-list-item-content>
                            <v-list-item-title>
                                <div class="text-subtitle-1 white--text">
                                    {{ item.CUIP }}
                                </div>
                            </v-list-item-title>
                            <v-list-item-subtitle class="d-inline-flex">
                                <v-chip small>{{ item.EstatusTexto }}</v-chip>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn icon color="#3fb1ce" large v-on:click="getUbicacionTableta(item)">
                                <v-icon>mdi-map-marker</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </template>
            </v-virtual-scroll>
        </v-card-text>
    </v-card>
</template>
<style scoped>
    .card--list {
        position: fixed;
        top: 88px;
        left: 24px;
    }
    .glass-menu-card {
        background: rgba(7, 13, 45, 0.88) !important;
        box-shadow: 0 4px 24px 2px rgba(84, 110, 229, 0.2) !important;
        backdrop-filter: blur(2px) !important;
        -webkit-backdrop-filter: blur(2px) !important;
        border: 1px solid var(--v-secondary-base) !important;
    }
</style>
<script>
    import { mapGetters, mapMutations } from 'vuex'
    export default {
        data() {
            return {
                tipoLecturaElegido: 0,
                search: '',
            }
        },

        computed: {
            ...mapGetters({
                personalActivo: 'getPersonalActivo',
                estatusPersonal: 'getEstatusPersonal',
                tipoLectura: 'getTipoLectura',
            }),

            filteredPersonalActivo() {
                return this.personalActivo.filter(personal => {
                    return personal.CUIP.toLowerCase().indexOf(this.search.toLowerCase()) >= 0
                })
            },

            selectTipoLectura() {
                var tipoLectura = [
                    {
                        id: 0,
                        nombre: 'TODOS',
                    },
                ].concat(this.tipoLectura)

                return tipoLectura
            },
        },

        methods: {
            ...mapMutations(['SET_SNACKBAR_MESSAGE']),
            getUbicacionTableta(personal) {
                this.SET_SNACKBAR_MESSAGE('Obteniendo ubicación de tableta.')
                this.$emit('fly', personal)
            },
        },
    }
</script>
