<template>
    <v-card v-if="show == true && personal" class="glass-map-card rounded-br-xl" tile>
        <v-card-title class="d-block" align="center">
            <v-avatar color="white" size="64">
                <v-img v-if="personal.foto.length > 0" :src="personal.foto"></v-img>
                <v-icon v-else size="32" color="secondary">mdi-account</v-icon>
            </v-avatar>
            <div class="text-h5 white--text mt-4">
                {{ personal.CUIP }}
            </div>
        </v-card-title>
        <v-card-text>
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <div class="text-caption text--secondary">Nombre</div>
                        <div class="text-subtitle-1 text--primary">{{ getFullName(personal) }}</div>

                        <div class="text-caption text--secondary mt-2">Puesto</div>
                        <div class="text-subtitle-1 text--primary">{{ personal.CatPuestoDependencia.catPuesto.title }}</div>

                        <div class="text-caption text--secondary mt-2">Area</div>
                        <div class="text-subtitle-1 text--primary">{{ personal.CatPuestoDependencia.catDependencia.nombre }}</div>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions class="justify-center mb-2">
            <v-btn outlined icon color="accent" v-on:click="closeMapCard"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-actions>
    </v-card>
</template>
<style scoped>
    .glass-map-card {
        background: rgba(7, 13, 45, 0.9) !important;
        box-shadow: 0 4px 24px 2px rgba(84, 110, 229, 0.2) !important;
        backdrop-filter: blur(2px) !important;
        -webkit-backdrop-filter: blur(2px) !important;
        border: 1px solid var(--v-secondary-base) !important;
    }
</style>
<script>
    import { mapGetters } from 'vuex'

    export default {
        props: {
            show: {
                Type: Boolean,
            },
            personal: {
                type: Object,
            },
        },

        methods: {
            closeMapCard() {
                this.$emit('close')
            },
        },
    }
</script>
