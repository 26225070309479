import { render, staticRenderFns } from "./ListaHitsComponent.vue?vue&type=template&id=54130b56&scoped=true&"
import script from "./ListaHitsComponent.vue?vue&type=script&lang=js&"
export * from "./ListaHitsComponent.vue?vue&type=script&lang=js&"
import style0 from "./ListaHitsComponent.vue?vue&type=style&index=0&id=54130b56&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54130b56",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VChip,VIcon,VVirtualScroll})
