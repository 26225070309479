<template>
    <v-dialog v-model="active" width="560" v-if="solicitud" persistent>
        <v-card tile class="glass-menu-card" v-if="personalData">
            <v-card-title>
                <div class="text-h5 white--text">Responder solicitud</div>
                <v-spacer> </v-spacer>
                <v-btn icon color="accent" v-on:click="closeRespuestaSolicitudDialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row align="center">
                        <v-col cols="2" align="center">
                            <v-avatar color="white" size="64">
                                <v-img v-if="personalData.foto.length > 0" :src="personalData.foto"></v-img>
                                <v-icon v-else size="32" color="secondary">mdi-account</v-icon>
                            </v-avatar>
                        </v-col>
                        <v-col cols="10">
                            <div class="text-subtitle-2 text--primary">{{ solicitud.personal }}</div>
                            <div class="text-subtitle-1 text--secondary">{{ personalData.nombre }}</div>
                        </v-col>
                        <v-col cols="12">
                            <v-tabs v-model="solicitudTabs" background-color="primary">
                                <v-tab>Mensaje</v-tab>
                                <v-tab>Enviados</v-tab>
                            </v-tabs>
                            <v-tabs-items v-model="solicitudTabs" class="transparent">
                                <v-tab-item>
                                    <v-alert color="secondary" icon="mdi-message" class="mt-4">
                                        <div class="text-body-2">
                                            {{ solicitud.mensaje }}
                                        </div>
                                    </v-alert>
                                    <v-img v-if="solicitud.adjunto" :src="getImageUrl(solicitud.adjunto)" max-height="240" class="mb-4"></v-img>
                                    <div class="text-body-2 text--disabled mb-4" v-else align="center">
                                        El mensaje no contiene archivo adjunto
                                    </div>
                                    <v-textarea v-model="mensajeRespuesta" solo label="Respuesta" rows="4" no-resize color="white"></v-textarea>
                                    <v-file-input v-model="file" solo label="Adjuntar archivo"></v-file-input>
                                    <v-row>
                                        <v-col cols="12" align="center">
                                            <v-btn
                                                rounded
                                                :class="{ 'secondary--button': mensajeRespuesta.length > 0 }"
                                                v-on:click="triggerSaveRespuesta"
                                                :disabled="loading || mensajeRespuesta.length == 0"
                                            >
                                                Enviar respuesta
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                                <v-tab-item>
                                    <v-list color="primary" v-if="respuestasAnteriores.length > 0">
                                        <v-list-item-group>
                                            <v-list-item v-for="respuesta in respuestasAnteriores" :key="respuesta.id" three-line>
                                                <v-list-item-content>
                                                    <v-list-item-subtitle>
                                                        {{ respuesta.fechaHora.date }}
                                                    </v-list-item-subtitle>
                                                    <v-list-item-title>
                                                        {{ respuesta.personal }}
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        {{ respuesta.mensaje }}
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                                <v-list-item-action v-if="respuesta.adjunto">
                                                    <v-btn fab small class="secondary--button" v-on:click="download(getImageUrl(respuesta.adjunto))"
                                                        ><v-icon>mdi-file</v-icon></v-btn
                                                    >
                                                </v-list-item-action>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list>
                                    <div v-else class="text-body-1 disabled--text mt-8" align="center">No se han enviado respuestas.</div>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<style scoped>
    .glass-menu-card {
        background: rgba(7, 13, 45, 1) !important;
        box-shadow: 0 4px 24px 2px rgba(84, 110, 229, 0.2) !important;
        backdrop-filter: blur(2px) !important;
        -webkit-backdrop-filter: blur(2px) !important;
        border: 1px solid var(--v-secondary-base) !important;
    }
    .menu--button {
        position: fixed;
        top: 8px;
        right: 270px;
        z-index: 9;
    }
    .secondary--icon {
        color: rgb(84, 110, 229) !important;
        color: linear-gradient(180deg, rgba(84, 110, 229, 1) 0%, rgba(95, 81, 252, 1) 100%) !important;
    }
</style>
<script>
    import moment from 'moment'
    import { mapGetters, mapActions } from 'vuex'

    export default {
        props: {
            solicitud: {
                type: Object,
                default: null,
            },

            active: {
                type: Boolean,
            },

            respuestasAnteriores: {
                type: Array,
            },
        },

        data() {
            return {
                mensajeRespuesta: '',
                file: null,
                loading: false,
                solicitudTabs: 0,
            }
        },

        computed: {
            ...mapGetters({
                solicitudes: 'getSolicitudes',
                cuip: 'getCuip',
                personalData: 'getPersonalData',
                respuestas: 'getRespuesta',
            }),
        },

        methods: {
            ...mapActions(['saveRespuesta', 'fetchRespuestaBySolicitud', 'fetchPersonalByCuip']),

            closeRespuestaSolicitudDialog() {
                this.$emit('close')
            },

            getImageUrl(image_name) {
                return 'http://eonproduccion.net/iver/files/' + image_name
            },

            async triggerSaveRespuesta() {
                this.loading = true

                var respuesta = new FormData()

                respuesta.append('personal', this.cuip)
                respuesta.append('mensaje', this.mensajeRespuesta)
                respuesta.append('adjunto', this.file)
                respuesta.append('idsolicitud', this.solicitud.id)
                respuesta.append('tipo', 2)
                respuesta.append('type', 'save')

                await this.saveRespuesta(respuesta)
                this.loading = false

                this.closeRespuestaSolicitudDialog()
            },
        },
    }
</script>
