import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueApexCharts from 'vue-apexcharts'
import { KinesisContainer, KinesisElement } from 'vue-kinesis'
import VueTypedJs from 'vue-typed-js'
import moment from 'moment'

moment.locale('es')

Vue.config.productionTip = false

Vue.use(VueApexCharts)
Vue.use(VueTypedJs)

Vue.component('apexchart', VueApexCharts)
Vue.component('kinesis-container', KinesisContainer)
Vue.component('kinesis-element', KinesisElement)

Vue.mixin({
    methods: {
        formatDate: function(date) {
            if (date) {
                return moment(date).format('DD/MM/YYYY')
            } else return null
        },
        getToday: function() {
            return moment().format('YYYY-MM-DD')
        },
        getHours: function(date) {
            return moment(date).format('h:mm a')
        },
        getFullName(person) {
            return person.nombre + ' ' + person.segundoNombre + ' ' + person.apaterno + ' ' + person.amaterno
        },
        getAge(birthdayDate) {
            return moment().diff(moment(birthdayDate, 'DD/MM/YYYY'), 'years')
        },
        logout() {
            localStorage.removeItem('nombre')
            localStorage.removeItem('token')
            localStorage.removeItem('perfil')
            localStorage.removeItem('idPersonal')
            localStorage.removeItem('cuip')
        },
        download: function(url) {
            window.open(url, '_blank')
        },
        getDocumentType: function(name) {
            if (name.substr(name.length - 3).toLowerCase() == 'jpg' || name.substr(name.length - 3).toLowerCase() == 'png') {
                return 'img'
            } else if (name.substr(name.length - 3).toLowerCase() == 'pdf') {
                return 'pdf'
            } else {
                return '0'
            }
        },
    },
})

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
}).$mount('#app')
