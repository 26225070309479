<template>
    <v-container class="mt-16">
        <v-row align="center">
            <v-col cols="12" align="center">
                <v-row>
                    <v-col cols="4" offset="4">
                        <v-text-field label="Buscar por CUIP" append-icon="mdi-magnify" v-model="search" solo dense></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="3" v-for="elemento in filteredPersonal" :key="elemento.id">
                <v-card class="glass-menu-card">
                    <v-card-title class=" d-block" align="center">
                        <v-icon size="80" class="secondary--icon">mdi-tablet-android</v-icon>
                        <div class="text-h5 mt-2">{{ elemento.CUIP }}</div>
                        <div class="text-h6">{{ elemento.nombre }}</div>
                        <v-chip class="my-2">{{ elemento.EstatusTexto }}</v-chip>
                        <div class="text-caption mt-4">
                            Última actualización: {{ formatDate(elemento.ultimaFecha.date) + ' a las ' + getHours(elemento.ultimaFecha.date) }}
                        </div>
                    </v-card-title>
                    <v-card-text></v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<style scoped>
    .secondary--icon {
        color: rgb(84, 110, 229) !important;
        color: linear-gradient(180deg, rgba(84, 110, 229, 1) 0%, rgba(95, 81, 252, 1) 100%) !important;
    }
</style>
<script>
    import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
                search: '',
            }
        },

        computed: {
            ...mapGetters({
                personal: 'getPersonal',
            }),

            filteredPersonal() {
                return this.personal.filter(elemento => {
                    return elemento.CUIP.toLowerCase().indexOf(this.search.toLowerCase()) >= 0
                })
            },
        },
    }
</script>
