<template>
    <map-component></map-component>
</template>
<script>
    import MapComponent from '../components/MapComponent.vue'

    export default {
        components: {
            MapComponent,
        },
    }
</script>
