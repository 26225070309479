<template>
    <v-dialog v-model="active" width="480" persistent overlay-color="red">
        <v-card color="error">
            <v-card-title class="justify-center">HIT REPORTADO</v-card-title>
            <v-card-actions class="justify-center">
                <v-btn text v-on:click="closeDialog()">Cerrar</v-btn>
                <v-btn v-on:click="verUltimoHit()">Ver en mapa</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        props: {
            active: {
                type: Boolean,
            },
        },

        methods: {
            closeDialog() {
                this.$emit('close')
            },

            verUltimoHit() {
                this.$emit('ver-ultimo-hit')
            },
        },
    }
</script>
