<template>
    <v-card v-if="show == true" class="glass-map-card rounded-br-xl" tile>
        <v-card-title class="d-block" align="center">
            <v-avatar size="72" color="accent">
                <v-icon color="primary" size="48">mdi-car</v-icon>
            </v-avatar>
            <div class="text-h5 white--text mt-4">
                {{ hit.placas }}
            </div>
            <div class="text-h6 white--text">
                {{ hit.marca + ' ' + hit.submarca + ' ' + hit.modelo + ' ' + hit.color }}
            </div>
            <v-chip small class="mb-4">VIN: {{ hit.vin }}</v-chip>
            <div class="text-caption white--text">Fecha y hora de reporte</div>
            <div class="text-body-1 white--text mb-2">{{ formatDate(hit.fechaReporte.date) }} a las {{ getHours(hit.fechaReporte.date) }}</div>
            <div class="text-caption white--text">Instancia</div>
            <div class="text-body-1 white--text mb-2">{{ getInstanciaById(7) }}</div>
            <div class="text-caption white--text">Lugar de reporte</div>
            <div class="text-body-1 white--text">{{ hit.lugarReporte }}</div>
        </v-card-title>
        <v-card-text>
            <v-container>
                <v-row align="center">
                    <v-col cols="12">
                        <v-divider class="white"></v-divider>
                    </v-col>
                    <v-col cols="12">
                        <v-row align="center">
                            <v-col cols="12">
                                <div class="text-caption white--text">Reporte realizado por</div>
                            </v-col>
                            <v-col cols="3" align="center">
                                <v-avatar color="white" size="64">
                                    <v-img v-if="personalData.foto.length > 0" :src="personalData.foto"></v-img>
                                    <v-icon v-else size="32" color="secondary">mdi-account</v-icon>
                                </v-avatar>
                            </v-col>
                            <v-col cols="9">
                                <div class="text-subtitle-2 white--text">{{ hit.personal }}</div>
                                <div class="text-subtitle-1 white--text">{{ getFullName(personalData) }}</div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" align="center">
                        <v-chip small>Hit registrado a las {{ getHours(hit.fechaHit.date) }}</v-chip>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions class="justify-center mb-2">
            <v-btn outlined icon color="accent" v-on:click="openRedVinculos(hit)"><v-icon>mdi-chart-timeline-variant</v-icon></v-btn>
            <v-btn outlined icon color="accent" v-on:click="closeHitCard"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-actions>
    </v-card>
</template>
<style scoped>
    .glass-map-card {
        background: rgba(7, 13, 45, 0.9) !important;
        box-shadow: 0 4px 24px 2px rgba(84, 110, 229, 0.2) !important;
        backdrop-filter: blur(2px) !important;
        -webkit-backdrop-filter: blur(2px) !important;
        border: 1px solid var(--v-secondary-base) !important;
    }
</style>
<script>
    import { mapGetters, mapActions } from 'vuex'

    export default {
        props: {
            show: {
                Type: Boolean,
            },

            hit: {
                Type: Object,
            },
        },

        data() {
            return {
                redVinculosDialog: false,
            }
        },

        computed: {
            ...mapGetters({
                instancias: 'getInstancias',
                personalData: 'getPersonalData',
                idPersonal: 'getIdPersonal',
            }),
        },

        methods: {
            ...mapActions(['fetchConsultaHitByPlaca']),

            closeHitCard() {
                this.$emit('close')
            },

            getInstanciaById(id) {
                var instancia = this.instancias.filter(instancia => {
                    return instancia.id == id
                })

                return instancia[0].nombre
            },

            async openRedVinculos(hit) {
                // this.redVinculosDialog = true
                let routeData = this.$router.resolve({ name: 'red_vinculos', params: { placas: hit.placas } })
                window.open(routeData.href, '_blank')
            },
        },
    }
</script>
