import Axios from 'axios'

const axios = Axios.create({
    baseURL: process.env.VUE_APP_API_URL,

    headers: {
        'Content-Type': 'application/json',
    },
})

const axiosFormData = Axios.create({
    baseURL: process.env.VUE_APP_API_URL,

    headers: {
        'Content-Type': 'multipart/form-data',
    },
})

const axiosEdoFuerza = Axios.create({
    baseURL: process.env.VUE_APP_API_EDO_FUERZA_URL,

    headers: {
        Authorization: localStorage.getItem('token'),
    },
})

export { axios, axiosEdoFuerza, axiosFormData }
