<template>
    <MglMap
        :accessToken="accessToken"
        :mapStyle="mapStyle"
        :center="center"
        :zoom="14"
        :max-zoom="18"
        :min-zoom="8"
        :bearing="-17.6"
        :pitch="45"
        :antialias="true"
        :doubleClickZoom="false"
        @load="mapLoaded"
    >
        <v-toolbar absolute collapse class="fixed-btn" color="transparent" flat>
            <v-btn class="menu--button mr-2" fab small v-on:click="padronVehiculosDrawer = true">
                <v-icon color="white">mdi-car-info</v-icon>
            </v-btn>
            <v-btn class="menu--button" fab small v-on:click="bandejaConsultasDrawer = true">
                <v-icon color="white">mdi-message</v-icon>
            </v-btn>
        </v-toolbar>
        <MglGeocoderControl :accessToken="accessToken" :input="defaultInput" placeholder="Buscar lugar o dirección" />

        <MglMarker
            v-for="personal in personal"
            :coordinates="[personal.lng, personal.lat]"
            :key="personal.idPersonal"
            v-on:click="muestraIncidente(personal, $event)"
        >
        </MglMarker>

        <MglMarker v-for="hit in hits" :coordinates="[hit.lngHit, hit.latHit]" color="#FFC107" v-on:click="muestraHit(hit, $event)" :key="hit.idHit">
        </MglMarker>

        <v-container fill-height>
            <v-row>
                <v-col cols="3" offset="8">
                    <transition name="fade">
                        <iph-map-card-component :show="showMapCard" v-on:close="closeMapCard" :personal="selectedPersonal"></iph-map-card-component>
                    </transition>
                    <transition name="fade">
                        <hit-card-component :show="showHitCard" v-on:close="closeHitCard" :hit="selectedHit"></hit-card-component>
                    </transition>
                </v-col>
            </v-row>
        </v-container>
        <lista-personal-component v-on:fly="muestraIncidente($event)"></lista-personal-component>
        <lista-hits-component v-on:fly="muestraHit($event)"></lista-hits-component>
        <v-navigation-drawer v-model="bandejaConsultasDrawer" width="400" app right temporary color="primary">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <div class="text-h6 white--text">Solicitudes</div>
                    </v-col>
                    <v-col>
                        <v-text-field solo dense label="Buscar" append-icon="mdi-magnify" light v-model="searchSolicitud"></v-text-field>
                        <v-chip v-on:click="verConsultasHoy = 0" class="px-4 mx-1" color="secondary">
                            Hoy
                            <v-icon v-if="verConsultasHoy == 0" small class="pl-2">mdi-check</v-icon>
                        </v-chip>
                        <v-chip v-on:click="verConsultasHoy = 1" class="px-4 mx-1" color="secondary">
                            Todos
                            <v-icon v-if="verConsultasHoy == 1" small class="pl-2">mdi-check</v-icon>
                        </v-chip>
                    </v-col>
                    <v-col cols="12">
                        <div class="text" v-if="filteredSolicitudesByCuip.length == 0">
                            No hay solicitudes.
                        </div>
                        <v-virtual-scroll :items="filteredSolicitudesByCuip" height="720" item-height="80" v-else>
                            <template v-slot:default="{ item }">
                                <v-list-item :key="item.id" three-line link v-on:click="openRespuestaSolicitudDialog(item)" dense>
                                    <v-list-item-icon>
                                        <v-avatar size="48" color="white">
                                            <v-icon color="secondary">mdi-message</v-icon>
                                        </v-avatar>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-subtitle>
                                            {{ formatDate(item.fechaHora.date) }} a las {{ getHours(item.fechaHora.date) }}
                                        </v-list-item-subtitle>
                                        <v-list-item-title>
                                            {{ item.personal }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ item.mensaje }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-virtual-scroll>
                    </v-col>
                </v-row>
            </v-container>
        </v-navigation-drawer>
        <v-navigation-drawer v-model="padronVehiculosDrawer" width="400" app right temporary color="primary">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <div class="text-h6 white--text">Padrón de vehículos con reporte</div>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field solo dense label="Buscar VIN o placas" append-icon="mdi-magnify" light v-model="searchVin"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-virtual-scroll :items="filteredPadron" height="720" item-height="280">
                            <template v-slot:default="{ item }">
                                <v-card :key="item.id" color="error" align="center" tile>
                                    <v-card-title class="d-inline-block">
                                        <v-avatar size="48" color="white">
                                            <v-icon color="secondary">mdi-car</v-icon>
                                        </v-avatar>
                                        <div class="text--primary text-h6">
                                            {{ item.marca + ' ' + item.submarca + ' ' + item.modelo + ' ' + item.color }}
                                        </div>
                                        <v-chip small class="mx-2">VIN: {{ item.vin }}</v-chip>
                                        <v-chip small class="mx-2">Placas: {{ item.placas }}</v-chip>
                                        <div class="text-caption text--secondary mt-2">
                                            Reportado el {{ formatDate(item.fechaReporte.date) }} a las {{ getHours(item.fechaReporte.date) }}
                                        </div>
                                    </v-card-title>
                                    <v-card-text>
                                        <div class="text-body-2">Detalles: {{ item.detalles }}</div>
                                    </v-card-text>
                                </v-card>
                            </template>
                        </v-virtual-scroll>
                    </v-col>
                </v-row>
            </v-container>
        </v-navigation-drawer>
        <respuesta-solicitud-dialog-component
            :solicitud="selectedSolicitud"
            :active="respuestaSolicitudDialog"
            v-on:close="respuestaSolicitudDialog = false"
            :respuestas-anteriores="respuestas"
        ></respuesta-solicitud-dialog-component>
        <nuevo-hit-dialog-component
            :active="nuevoHitDialog"
            v-on:close="nuevoHitDialog = false"
            v-on:ver-ultimo-hit="muestraHit(hits[hits.length - 1])"
        ></nuevo-hit-dialog-component>
    </MglMap>
</template>
<style scoped>
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    .fixed-btn {
        position: fixed;
        bottom: 24px;
        right: 250px;
    }
    .menu--button {
        border-radius: 12px !important;
        background: linear-gradient(145deg, #5a76f5, #4c63ce) !important;
    }
</style>
<script>
    import _ from 'lodash'
    import moment from 'moment'
    import { mapGetters, mapActions } from 'vuex'
    import Mapbox from 'mapbox-gl'
    import { MglMap, MglMarker, MglPopup } from 'vue-mapbox'
    import MglGeocoderControl from 'vue-mapbox-geocoder'
    import IphMapCardComponent from './map/IphMapCardComponent.vue'
    import ListaPersonalComponent from './map/ListaPersonalComponent.vue'
    import HitCardComponent from './map/HitCardComponent.vue'

    import ListaHitsComponent from './map/ListaHitsComponent.vue'

    import RespuestaSolicitudDialogComponent from './RespuestaSolicitudDialogComponent'
    import NuevoHitDialogComponent from './NuevoHitDialogComponent.vue'

    import { Howl, Howler } from 'howler'

    let mapRef = {}

    export default {
        components: {
            MglMap,
            MglMarker,
            MglGeocoderControl,
            MglPopup,
            IphMapCardComponent,
            ListaPersonalComponent,
            ListaHitsComponent,
            HitCardComponent,

            RespuestaSolicitudDialogComponent,
            NuevoHitDialogComponent,
        },

        async created() {
            await this.fetchHits()
            await this.fetchPadron()
            await this.fetchPersonal()
            await this.fetchSolicitudes()
            this.mapbox = Mapbox
            this.desactivaAlerta = false
        },

        mounted() {
            setInterval(() => {
                this.fetchHits()
                // this.fetchPadron()
                this.fetchPersonal()
                this.fetchSolicitudes()
            }, 30000)
        },

        data() {
            return {
                accessToken: 'pk.eyJ1IjoiZ3VzdG1nIiwiYSI6ImNramtlb2liazRwbDgzM255ajJleWd2cWUifQ.IXSXU4vuLx_zyN5c8lcvYg',
                mapStyle: 'mapbox://styles/gustmg/ckmzmsp5v0aeh17mwe7s6ywlr',

                desactivaAlerta: true,

                defaultInput: '',
                center: [-98.2391268, 19.316547],

                search: '',

                showMapCard: false,

                selectedHit: null,
                showHitCard: false,

                query: '',

                hitDetectado: false,
                bandejaConsultasDrawer: false,
                padronVehiculosDrawer: false,

                respuestaSolicitudDialog: false,
                selectedSolicitud: null,

                verConsultasHoy: 0,

                searchSolicitud: '',
                searchVin: '',

                nuevoHitDialog: false,

                sound: new Howl({
                    src: [require('@/assets/sound/alert.mp3')],
                }),
            }
        },

        computed: {
            ...mapGetters({
                personal: 'getPersonal',
                hits: 'getHits',
                solicitudes: 'getSolicitudes',
                padron: 'getPadron',
                selectedPersonal: 'getPersonalData',
                respuestas: 'getRespuesta',
                numeroHits: 'getNumeroHits',
            }),

            sortedSolicitudes() {
                return this.solicitudes.sort(function compare(a, b) {
                    var dateA = new Date(a.fechaHora.date)
                    var dateB = new Date(b.fechaHora.date)
                    return dateB - dateA
                })
            },

            filteredSolicitudes() {
                if (this.verConsultasHoy == 0) {
                    return this.sortedSolicitudes.filter(solicitud => {
                        if (moment().diff(solicitud.fechaHora.date, 'days') == 0) {
                            return moment(solicitud.fechaHora.date)
                        }
                    })
                } else {
                    return this.sortedSolicitudes
                }
            },

            filteredSolicitudesByCuip() {
                return this.filteredSolicitudes.filter(solicitud => {
                    return solicitud.personal.toLowerCase().indexOf(this.searchSolicitud.toLowerCase()) >= 0
                })
            },

            filteredPadron() {
                return this.padron.filter(vehiculo => {
                    return (
                        vehiculo.vin.toLowerCase().indexOf(this.searchVin.toLowerCase()) >= 0 ||
                        vehiculo.placas.toLowerCase().indexOf(this.searchVin.toLowerCase()) >= 0
                    )
                })
            },
        },

        watch: {
            numeroHits(newValue, oldValue) {
                if (newValue > oldValue) {
                    if (!this.desactivaAlerta) {
                        this.nuevoHitDialog = true
                        this.sound.play()
                    }
                }
            },
        },

        methods: {
            ...mapActions(['fetchHits', 'fetchPadron', 'fetchPersonal', 'fetchSolicitudes', 'fetchPersonalByCuip', 'fetchRespuestaBySolicitud']),

            muestraIncidente: async function(personal) {
                this.showMapCard = false
                this.showHitCard = false
                await this.fetchPersonalByCuip(personal.CUIP)
                mapRef.flyTo({
                    center: [personal.lng, personal.lat],
                    zoom: 16,
                })
                this.showMapCard = true
                this.nuevoHitDialog = false
            },

            muestraHit: async function(hit) {
                this.showMapCard = false
                this.showHitCard = false
                await this.fetchPersonalByCuip(hit.personal)
                mapRef.flyTo({
                    center: [hit.lngHit, hit.latHit],
                    zoom: 16,
                })
                this.selectedHit = hit
                this.showHitCard = true
                this.nuevoHitDialog = false
            },

            closeMapCard() {
                this.showMapCard = false
                mapRef.zoomTo(14)
            },

            closeHitCard() {
                this.showHitCard = false
                mapRef.zoomTo(14)
            },

            mapLoaded(event) {
                mapRef = event.map

                // this.getRoute()
            },

            async openRespuestaSolicitudDialog(solicitud) {
                await this.fetchPersonalByCuip(solicitud.personal)
                await this.fetchRespuestaBySolicitud(solicitud.id)
                this.bandejaConsultasDrawer = false
                this.selectedSolicitud = solicitud
                this.respuestaSolicitudDialog = true
            },
        },
    }
</script>
