<template>
    <v-dialog v-model="menuNavigation" fullscreen transition="dialog-bottom-transition">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" v-on:click="menuNavigation = true" fixed class="px-8 secondary--button menu--button" rounded>
                <v-icon class="mr-2" color="white">mdi-menu</v-icon>
                <span class="white--text">Menú</span>
            </v-btn>
        </template>
        <v-card class="glass-menu" tile>
            <v-card-text>
                <v-container>
                    <v-row align="center">
                        <v-col cols="2" align="center">
                            <kinesis-container>
                                <kinesis-element :strength="16" type="depth">
                                    <img src="@/assets/img/policia.png" width="180" class="logo" />
                                </kinesis-element>
                            </kinesis-container>
                        </v-col>
                        <v-col cols="8">
                            <div class="text-subtitle-1 white--text">{{ getDayName }}, {{ getDay }} de {{ getMonth }} de {{ getYear }}</div>
                            <vue-typed-js :strings="[nombre, '¿qué desea consultar?']" :loop="true" :smartBackspace="true" :backSpeed="30" :backDelay="3000">
                                <h1 class="text-h4 white--text">Bienvenido, <span class="typing"></span></h1>
                            </vue-typed-js>
                        </v-col>
                        <v-col cols="2">
                            <v-btn v-on:click="triggerLogout()">Cerrar sesión</v-btn>
                        </v-col>
                    </v-row>
                    <v-row class="mt-16">
                        <v-col cols="3">
                            <v-card class="glass-menu-card rounded-tr-xl" tile outlined>
                                <v-card-title class="d-block" align="center">
                                    <v-icon size="80" class="secondary--icon">mdi-map-marker</v-icon>
                                    <div class="text-h5 white--text">Mapa</div>
                                </v-card-title>
                                <v-card-text>
                                    <div class="text-body-2 white--text" align="center">
                                        Lista de hits, bandeja de consultas de placas.
                                    </div>
                                </v-card-text>
                                <v-card-actions class="justify-center mb-4">
                                    <v-btn class="secondary--button px-8 white--text" rounded to="/mapa" v-on:click="menuNavigation = false">Entrar</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                        <v-col cols="3">
                            <v-card class="glass-menu-card rounded-tr-xl" tile outlined>
                                <v-card-title class="d-block" align="center">
                                    <v-icon size="80" class="secondary--icon">mdi-tablet-android</v-icon>
                                    <div class="text-h5 white--text">Tabletas</div>
                                </v-card-title>
                                <v-card-text>
                                    <div class="text-body-2 white--text" align="center">
                                        Lista de hits, bandeja de consultas de placas.
                                    </div>
                                </v-card-text>
                                <v-card-actions class="justify-center mb-4">
                                    <v-btn class="secondary--button px-8 white--text" rounded to="/tabletas" v-on:click="menuNavigation = false"
                                        >Entrar</v-btn
                                    >
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn outlined rounded color="secondary" class="px-8" v-on:click="menuNavigation = false">
                    Cerrar menú
                    <v-icon class="ml-2">mdi-close</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<style scoped>
    .full-height {
        height: 100vh !important;
    }
    .logo {
        -webkit-filter: drop-shadow(2px 2px 8px #b3b3b3);
        filter: drop-shadow(2px 2px 8px #b3b3b3);
    }
    .glass-menu-card {
        background: rgba(7, 13, 45, 1) !important;
        box-shadow: 0 4px 24px 2px rgba(84, 110, 229, 0.2) !important;
        backdrop-filter: blur(2px) !important;
        -webkit-backdrop-filter: blur(2px) !important;
        border: 1px solid var(--v-secondary-base) !important;
    }
    .glass-menu {
        background: rgba(4, 4, 4, 0.78) !important;
        backdrop-filter: blur(4px) !important;
        -webkit-backdrop-filter: blur(2px) !important;
        border-radius: 0 !important;
    }

    .menu--button {
        position: fixed;
        top: 24px;
        left: 24px;
        z-index: 9;
    }

    .secondary--icon {
        color: rgb(84, 110, 229) !important;
        color: linear-gradient(180deg, rgba(84, 110, 229, 1) 0%, rgba(95, 81, 252, 1) 100%) !important;
    }
</style>
<script>
    import moment from 'moment'
    import { mapMutations, mapGetters } from 'vuex'

    export default {
        data() {
            return {
                menuNavigation: false,
            }
        },

        computed: {
            ...mapGetters({
                nombre: 'getNombre',
            }),

            getDayName() {
                return moment().format('dddd')
            },

            getDay() {
                return moment().format('DD')
            },

            getMonth() {
                return moment().format('MMMM')
            },

            getYear() {
                return moment().format('YYYY')
            },
        },

        methods: {
            ...mapMutations(['SET_SNACKBAR_MESSAGE']),

            triggerLogout: function() {
                this.SET_SNACKBAR_MESSAGE('Cerrando sesión...')
                this.logout()
                window.location.reload()
                // this.$router.replace('/')
            },
        },
    }
</script>
