<template>
    <v-app>
        <v-main class="primary">
            <navigation-menu-dialog-component v-if="token.length > 0"></navigation-menu-dialog-component>
            <router-view></router-view>
        </v-main>
        <v-snackbar v-model="snackbar.active" color="accent">
            {{ snackbar.message }}
        </v-snackbar>
    </v-app>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'
    import NavigationMenuDialogComponent from './components/NavigationMenuDialogComponent.vue'

    export default {
        components: {
            NavigationMenuDialogComponent,
        },

        async mounted() {
            await this.fetchEstatusPersonal()
            await this.fetchInstancias()
            await this.fetchTipoLectura()
        },

        computed: {
            ...mapGetters({
                token: 'getToken',
                snackbar: 'getSnackbar',
            }),
        },

        methods: {
            ...mapActions(['fetchEstatusPersonal', 'fetchInstancias', 'fetchTipoLectura']),
        },
    }
</script>
